<template>
  <div>

    <div v-if="options.sidebarContent.body" class="px-2 py-2" v-html="$t(options.sidebarContent.body)">
      
    </div>
    <!--
    <b-list-group class="mt-3 rounded-0" v-if="options.sidebarContent.body">
      <b-list-group-item class="bg-transparent">
        <span class="" v-html="$t(options.sidebarContent.body)" />
      </b-list-group-item>
    </b-list-group>
-->
    <div v-else>
      <div class="text-center">
        <!-- Name -->
        <b-card-text class="mt-2 h4 color-inherit text-reset">
          {{ options.sidebarContent.title }}
        </b-card-text>

        <b-card-text
          class="mt-2 ml-2 mr-2 color-inherit text-reset"
          style="text-align: justify"
        >
          {{ options.sidebarContent.subtitle }}
        </b-card-text>
      </div>

      <b-list-group class="mt-3 rounded-0">
        <b-list-group-item
          v-for="item in options.sidebarContent.items"
          :key="item.title"
          class="bg-transparent"
        >
          <span class="font-weight-bold" v-text="$t(item.title)" />
          <span class="" v-html="$t(item.content)" />
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>
  
  <script>
import
{
  BAvatar, BListGroup, BListGroupItem, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
  },

  props: {

    options: {
      type: Object,
      required: true,
      default: {}
    },



  },

  data()
  {
    return {
    }
  },
}
  </script>
  
