<template>
  <section id="pf-asociar-lotes">

    <b-row>
      <b-col>
        <BrunaForm ref="mmpppRef" :fields="this.mmpp.fields" :card="this.mmpp.card">
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BrunaForm ref="mmpppInfoRef" :fields="this.mmpp_info.fields" :card="this.mmpp_info.card">
        </BrunaForm>
      </b-col>
    </b-row>

    <b-card>
      <b-card-body>
        <b-button variant="primary" class="mt-4 float-right" @click="addNewMmpp" size="lg">Guardar</b-button>
      </b-card-body>
    </b-card>

  </section>
</template>
<script>
//   import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from '@/router'


export default {
  components: {
    //   AgGridTable,
    BRow,
    BCol,
    BButton,
    BrunaForm,
    ToastificationContent
  },
  data() {
    return {

      /* formatData: {

        selected: false,
        selectBrunaTable: null,
        selectQadTable: null,

      }, */

      mmpp: {
        card: {
          title: "Características",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: '',
            body: "Solo ingresar puntos para delimitar decimales"
          }
        },
        fields: [
          {
            label: "CÓDIGO",
            type: "input",
            id: 'codigo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "DESCRIPCIÓN",
            type: "input",
            id: 'descripcion',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "CONGELADO / ENFRIADO",
            type: "select",
            id: 'estado',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: 'CONGELADO', text: "CONGELADO" },
              { value: 'ENFRIADO', text: "ENFRIADO" },
            ],
            value: null
          },
          {
            label: "VIDA ÚTIL",
            type: "select",
            id: 'vida_util',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: 'Dias', text: "Dias" },
              { value: 'Meses', text: "Meses" },
            ],
            value: null
          },
          {
            label: "CANTIDAD VIDA ÚTIL",
            type: "input",
            id: 'cantidad_vida_util',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          }
        ]
      },
      mmpp_info: {
        card: {
          title: "Información Nutricional",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: '',
            body: ""
          }
        },
        fields: [
          {
            label: "ENERGÍA (kcal)",
            type: "input",
            id: 'energia',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "PROTEÍNAS (g)",
            type: "input",
            id: 'proteina',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "HIDRATOS DE CARBONO (g)",
            type: "input",
            id: 'hidrato_carbono',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "AZÚCARES TOTALES (g)",
            type: "input",
            id: 'totales_azucar',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "GRASAS (g)",
            type: "input",
            id: 'grasas',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "GRASAS SATURADAS (g)",
            type: "input",
            id: 'grasa_saturada',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "GRASAS POLIINSATURADAS (g)",
            type: "input",
            id: 'grasa_poliinsaturada',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "GRASAS MONOINSATURADAS (g)",
            type: "input",
            id: 'monoinsaturada',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "SODIO (mg)",
            type: "input",
            id: 'sodio',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "COLESTEROL (mg)",
            type: "input",
            id: 'colesterol',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "GRASAS TRANS (g)",
            type: "input",
            id: 'grasas_trans',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "HUMEDAD (%)",
            type: "input",
            id: 'humedad',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "BEEF (%)",
            type: "input",
            id: 'beef',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "COLÁGENO (%)",
            type: "input",
            id: 'colageno',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          }
        ]
      },

    };
  },
  methods: {

    formatData()
    {
      let mmpp = this.mmpp.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      let mmpp_info = this.mmpp_info.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      let data = {...mmpp, ...mmpp_info}
      return { ...mmpp, ...mmpp_info }


    },

    addNewMmpp() {
      // console.log(JSON.stringify(this.formatData(), null, 2))
      Promise.all([
        this.$refs.mmpppRef.validate(),
        this.$refs.mmpppInfoRef.validate()
      ]).then(([mmppValid, mmppInfoValid]) => {
        if (mmppValid, mmppInfoValid) {

          useApiServices.postMmppCreate(this.formatData(), this)
            .then((response) => {
              console.log(response);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });

              router.push({ path: `/pf/data/materia-prima/historico` })

            })
            .catch((error) => {

              console.log(error)

              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });


    }
  },
  mounted() {

  },

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}

.mt-3 {
  border: 1px solid green !important;
  background-color: green !important;
  color: white !important;
}

.mt-3:hover {

  transition: 0.3s ease-in !important;
  border: 1px solid green !important;
  background-color: transparent !important;
  color: green !important;
  color: white;
}

.mt-2 {
  border: 1px solid red !important;
  background-color: red !important;
  color: white !important;
}

.mt-2:hover {

  transition: 0.5s ease-in !important;
  border: 1px solid red !important;
  background-color: transparent !important;
  color: red !important;
}
</style>